exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/All.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/Catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digitall-js": () => import("./../../../src/pages/Digitall.js" /* webpackChunkName: "component---src-pages-digitall-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/Equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-furnituree-js": () => import("./../../../src/pages/Furnituree.js" /* webpackChunkName: "component---src-pages-furnituree-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactiv-js": () => import("./../../../src/pages/Interactiv.js" /* webpackChunkName: "component---src-pages-interactiv-js" */),
  "component---src-pages-monoblock-js": () => import("./../../../src/pages/Monoblock.js" /* webpackChunkName: "component---src-pages-monoblock-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-printer-js": () => import("./../../../src/pages/Printer.js" /* webpackChunkName: "component---src-pages-printer-js" */),
  "component---src-pages-sets-js": () => import("./../../../src/pages/Sets.js" /* webpackChunkName: "component---src-pages-sets-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/Software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

